<template lang="pug">
  div.page-statistics
    loading(:active.sync="busy", :is-full-page="true")
    .widget(v-if="filters")
      .widget-header
        h1.title Rejected Events
        i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'rejectedEvents'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            ov-date-time-picker(v-model="filters.datePicker")
            select-advertiser(v-model="filters.advertiser_id")
            select-offer(v-model="filters.offer_id")
            select-publisher(v-model="filters.publisher_id")
            select-placement(v-model="filters.placement_id")
            .form-group
              label Reject Reason
              select.form-control(type="text", v-model="filters.reject_reason")
                option(:value="''") - All -
                option(v-for="o in rejectReasons", :value="o.v") {{ o.v }}
            
            .form-group
              label Rejection Type
              select.form-control(type="text", v-model="filters.rejection_type")
                option(:value="''") - All -
                option(:value="0") Real Time
                option(:value="1") Post-Attribution

          .form-row-end.desktop
            .form-group
              label &nbsp;
              // button.btn.btn-primary(type="submit") Go
              b-button-group
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(true)")
                    i.la.la-download
                    | Export

          .w-100.mobile.p-2.border-top.position-sticky.bg-white(style="bottom: 0; z-index: 2;")
            b-button-group.submit-filters-button
              b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                b-dropdown-item(@click="loadData(1)")
                  i.la.la-download.mr-1
                  | Export

        p.clearfix &nbsp;

        paginate(:paginator="paginate", @update-page-size="loadData")

        .ov-table-wrapper
          table.table.table-bordered.table-condensed
            thead
              tr
                th
                  .text ID
                th
                  .text Advertiser
                th
                  .text Offer
                th
                  .text.has-buttons Placement
                    a.toggle-mode(href="javascript:void(0);", @click="togglePlacementViewMode()")
                      i.la.la-arrows-h
                th
                  .text Publisher
                th
                  .text ADV Subsource
                th
                  .text Subsource
                th
                  .text ClickID
                th
                  .text ClickIP
                th
                  .text ClickTime
                th
                  .text EventTime
                th
                  .text Rejection Type
                th
                  .text Reject Reason
                th
                  .text Reject Value
                th
                  .text Reject Sub Reason
                th
                  .text &nbsp;
                th
                  .text Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="17") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td
                  span(v-if="r.advertiser")
                    entity(type="advertiser", :id="r.advertiser.id", :name.sync="r.advertiser.name", @click="appendEntity(r,'advertiser')")
                td
                  span(v-if="r.offer")
                    entity(type="offer", :id="r.offer.id", :name.sync="r.offer.name", @click="appendEntity(r,'offer')")
                td
                  span(v-if="r.placement")
                    entity(type="placement", :id="r.placement.id", :name.sync="r.placement.name", :mode="placementViewMode", @click="appendEntity(r,'placement')", :data="r.placement")
                td
                  span(v-if="r.publisher")
                    entity(type="publisher", :id="r.publisher.id", :name.sync="r.publisher.name", @click="appendEntity(r,'publisher')")
                td {{ r.adv_subsource }}
                td
                  entity(v-if="r.subsource", type="sub1", :id="r.subsource.id", :name.sync="r.subsource.name")
                  entity(v-if="r.subsource_2", type="sub2", :id="r.subsource_2.id", :name.sync="r.subsource_2.name")
                td
                  router-link.btn-edit(v-if="r.click", v-b-tooltip.hover.bottom, title="View",
                    :to="{name:'click-view', params: {id: r.click.id}}") {{ r.click.id }}
                td
                  span(v-if="r.click") {{ r.click.ip_fmt }}
                td
                  span(v-if="r.click") {{ r.click.created_at }}
                td {{ r.created_at }}
                td {{ r.is_post_attribution ? 'Post-Attribution' : 'Real Time' }}
                td {{ r.reject_reason }}
                td {{ r.reject_value }}
                td {{ r.reject_sub_reason }}
                td.info-buttons
                  a.btn(href="javascript:void(0)", :id="`pop-params-${r.id}`", v-b-tooltip.hover.bottom, title="Params" )
                    i.la.la-info-circle
                  b-popover.params-popover(:target="`pop-params-${r.id}`", placement="left", title="Params")
                    pre.text-small {{ r.params }}
                td.actions &nbsp;
            //tfoot
              tr
                td(colspan="6") &nbsp;
                td.text-center {{ summary.payout }}
                td(colspan="8") &nbsp;

        paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.params-popover {
  max-width: 350px;
}

.info-buttons {
  a.btn {
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    text-align: center;
    border-radius: 100%;
  }
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'Statistics',
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  async beforeRouteEnter(to, from, next) {
    let dateStartQ = to.query.date_start;

    let dateStartObj = null;
    if (dateStartQ) {
      dateStartObj = moment(dateStartQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateStartObj || !dateStartObj.isValid()) {
      dateStartObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 00:00',
        'DD/MM/YYYY HH:mm',
      );
    }
    let dateEndQ = to.query.date_end;

    let dateEndObj = null;
    if (dateEndQ) {
      dateEndObj = moment(dateEndQ, 'YYYY-MM-DD HH:mm');
    }
    if (!dateEndObj || !dateEndObj.isValid()) {
      dateEndObj = moment(
        moment()
          .subtract(-(new Date().getTimezoneOffset() / 60), 'hours')
          .format('DD/MM/YYYY') + ' 23:59',
        'DD/MM/YYYY HH:mm',
      );
    }

    let selectedAdvertiserOptions = await Vue.ovData.advertiser.getSelectedOptions(
      to.query.advertiser_id,
    );
    let selectedPublisherOptions = await Vue.ovData.publisher.getSelectedOptions(
      to.query.publisher_id,
    );
    let selectedOfferOptions = await Vue.ovData.offer.getSelectedOptions(to.query.offer_id);
    let selectedPlacementOptions = await Vue.ovData.placement.getSelectedOptions(
      to.query.placement_id,
    );

    next(async (vm) => {
      vm.filters.offer_id = selectedOfferOptions;
      vm.filters.publisher_id = selectedPublisherOptions;
      vm.filters.placement_id = selectedPlacementOptions;
      vm.filters.advertiser_id = selectedAdvertiserOptions;
      vm.filters.datePicker.dateRange.startDate = dateStartObj;
      vm.filters.datePicker.dateRange.endDate = dateEndObj;

      vm.loadData();
      return vm;
    });
  },
  data() {
    const defaultFilters = {
      keyword: '',
      reject_reason: '',
      rejection_type: '',
      cohort: false,
      daily: false,
      offer_id: [],
      placement_id: [],
      publisher_id: [],
      advertiser_id: [],
      datePicker: {
        timezone: 0,
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
    };
    return {
      areFiltersOpened: false,
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 30,
        onPageChange: () => {
          this.loadData();
        },
      },
      records: [],
      rejectReasons: Vue.ovData.general.rejectReasons,
      summary: {
        payout: 0,
      },
      placementViewMode: 'mini',
      defaultFilters,
      filters: { ...defaultFilters },
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
    };
  },
  methods: {
    togglePlacementViewMode() {
      if (this.placementViewMode === 'mini') {
        this.placementViewMode = 'normal';
      } else {
        this.placementViewMode = 'mini';
      }
    },

    appendEntity(record, entityType) {
      let entity = record[entityType];
      if (!entity || !this.filters[entityType + '_id']) {
        return;
      }
      for (let k in this.filters[entityType + '_id']) {
        if (+this.filters[entityType + '_id'].id === +entity.id) {
          return false;
        }
      }

      switch (entityType) {
        case 'placement':
        case 'offer':
          this.filters[entityType + '_id'].push({
            id: entity.id,
            name: `[${entity.id}] ${entity.name}`,
          });
          break;
        case 'advertiser':
        case 'publisher':
          this.filters[entityType + '_id'].push({
            v: entity.id,
            t: `[${entity.id}] ${entity.name}`,
          });
          break;
      }
      this.$forceUpdate();
    },

    async report(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to report this event?',
      );
      if (confirmed) {
        try {
          let resp = await this.$ovReq.post('event/report', { id: r.id });
          r.report_time = resp.report_time;
          r.status = resp.status;
        } catch (e) {
          console.error(e);
        }
      }
    },

    async convert(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm('Generate conversion from this event?');
      if (confirmed) {
        try {
          let resp = await this.$ovReq.post('event/generateConversion', { id: r.id });
          r.report_time = resp.report_time;
          r.status = resp.status;
        } catch (e) {
          console.error(e);
        }
      }
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let params = {
        export: doExport ? 1 : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        reject_reason: this.filters.reject_reason,
        rejection_type: this.filters.rejection_type,
        //click_from: this.filters.click_from ? moment(this.filters.click_from).format('YYYY-MM-DD') : '',

        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),

        offer_id: this.filters.offer_id.map((r) => r.id).join(','),
        placement_id: this.filters.placement_id.map((r) => r.id).join(','),

        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('rejected/getList', { params });
        if (doExport) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
          this.summary = resp.summary;
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
